import React, { useRef, useEffect, useState, useCallback } from "react";
import { invalidate, ThreeEvent, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useFloorplannerStore } from "../../store/floorplannerStore";
import {
  WallShapeType,
  Point,
  WallType,
  WallConnectionStart,
  WallConnectionEnd,
} from "../../types/wallTypes";
import { calculateLineVertices } from "./calculateLineVertices";
import { createShapes } from "./createShapes";
import { trimIntersectingLines } from "./trimIntersectingLines";
import {
  createMiddleDragHandle,
  handleSize,
  handleWidth,
  tubeRadius,
} from "./createMiddleDragHandle";
import { updateWallConnections } from "./updateWallConnections";
import ClipperLib from "clipper-lib";
import { observer } from "mobx-react-lite";

const SNAP_THRESHOLD = 0.2; // Threshold distance for snapping

const WallStructure = observer(() => {
  const floorplannerStore = useFloorplannerStore();
  const {
    walls,
    setWalls,
    wallWidth,
    lineWidth,
    lineColor,
    fillColor,
    hoverColor,
  } = floorplannerStore;
  const [selectedWallIndex, setSelectedWallIndex] = useState<number | null>(
    null,
  );
  const dragWall = useRef<WallType | null>(null);
  const dragOffset = useRef<[number, number, number, number] | null>(null);
  const dragHandle = useRef<{
    handle: string;
    startPosition: THREE.Vector3;
    endPosition: THREE.Vector3;
  }>({
    handle: "",
    startPosition: new THREE.Vector3(),
    endPosition: new THREE.Vector3(),
  });
  const [dragging, setDragging] = useState(false);
  const { camera, gl } = useThree();
  const [meshWalls, setMeshWalls] = useState<THREE.Mesh[]>([]);
  const scene = useThree((state) => state.scene);

  const disposeMesh = useCallback((mesh: THREE.Mesh) => {
    mesh.traverse((child: THREE.Object3D) => {
      const _child = child as THREE.Mesh;
      if (_child.geometry) _child.geometry.dispose();
      if (_child.material) {
        if (Array.isArray(_child.material)) {
          _child.material.forEach((material) => material.dispose());
        } else {
          _child.material.dispose();
        }
      }
    });
  }, []);

  const updateDragHandles = useCallback((handle: string, wall: WallType) => {
    const handleOffset = 0.035;
    const start = new THREE.Vector3(wall.start.x, wall.start.y, 0);
    const end = new THREE.Vector3(wall.end.x, wall.end.y, 0);
    const wallDirection = new THREE.Vector3()
      .subVectors(end, start)
      .normalize();
    const startHandle = new THREE.Vector3().addVectors(
      start,
      wallDirection.clone().multiplyScalar(-handleOffset),
    );
    const endHandle = new THREE.Vector3().addVectors(
      end,
      wallDirection.clone().multiplyScalar(handleOffset),
    );
    dragHandle.current.handle = handle;
    dragHandle.current.startPosition = startHandle;
    dragHandle.current.endPosition = endHandle;
  }, []);

  useEffect(() => {
    const handleContextLost = (event: Event) => {
      event.preventDefault();
      console.warn("WebGL context lost");
    };

    const handleContextRestored = () => {
      console.log("WebGL context restored");
      invalidate();
    };

    gl.domElement.addEventListener(
      "webglcontextlost",
      handleContextLost as EventListener,
    );
    gl.domElement.addEventListener(
      "webglcontextrestored",
      handleContextRestored as EventListener,
    );

    return () => {
      gl.domElement.removeEventListener(
        "webglcontextlost",
        handleContextLost as EventListener,
      );
      gl.domElement.removeEventListener(
        "webglcontextrestored",
        handleContextRestored as EventListener,
      );
    };
  }, [gl.domElement]);

  useEffect(() => {
    const filledLines: WallShapeType[] = [];
    const leftLines: WallShapeType[] = [];
    const rightLines: WallShapeType[] = [];
    const endCaps: WallShapeType[] = [];

    for (const wall of Object.values(walls)) {
      const customWallWidth = wall.wallWidth || wallWidth;
      const customLineWidth = wall.lineWidth || lineWidth;
      filledLines.push({
        wall: wall,
        paths: calculateLineVertices(
          wall.start,
          wall.end,
          0,
          customLineWidth,
          customWallWidth,
          "inner",
        ),
        lineSide: "inner",
      });
      leftLines.push({
        wall: wall,
        paths: calculateLineVertices(
          wall.start,
          wall.end,
          -(customWallWidth + customLineWidth),
          customLineWidth,
          customWallWidth,
          "left",
        ),
        lineSide: "left",
      });
      rightLines.push({
        wall: wall,
        paths: calculateLineVertices(
          wall.start,
          wall.end,
          customWallWidth + customLineWidth,
          customLineWidth,
          customWallWidth,
          "right",
        ),
        lineSide: "right",
      });
      let endCap = "";
      const endPoints = wall.connections.filter(
        (c) =>
          c.sourcePosition === WallConnectionEnd ||
          c.sourcePosition === WallConnectionStart,
      );
      if (
        endPoints.filter(
          (c) =>
            c.sourcePosition === WallConnectionStart ||
            c.sourcePosition === WallConnectionEnd,
        ).length === 0
      ) {
        endCap = "endCapBoth";
      } else if (
        endPoints.filter((c) => c.sourcePosition === WallConnectionStart)
          .length === 0
      ) {
        endCap = "endCapStart";
      } else if (
        endPoints.filter((c) => c.sourcePosition === WallConnectionEnd)
          .length === 0
      ) {
        endCap = "endCapEnd";
      }
      if (endCap !== "") {
        endCaps.push({
          wall: wall,
          paths: calculateLineVertices(
            wall.start,
            wall.end,
            0,
            customLineWidth,
            customWallWidth,
            endCap,
          ),
          lineSide: "endcap",
        });
      } else {
        endCaps.push({
          wall: wall,
          paths: [],
          lineSide: "endcap",
        });
      }
    }

    trimIntersectingLines(rightLines, leftLines, walls);
    trimIntersectingLines(leftLines, rightLines, walls);
    trimIntersectingLines(filledLines, undefined, walls);

    const newMeshWalls: THREE.Mesh[] = [];

    const addHolesToShapes = (
      shapes: THREE.Shape[],
      polygons: THREE.Vector2[][],
    ) => {
      if (polygons.length === 0) return;

      const scale = 100000;
      const holeInset = 0.99; // Adjust this value to control the offset (0.99 for 1% inward)

      shapes.forEach((shape) => {
        const shapePoints: ClipperLib.Path = shape
          .getPoints()
          .map((p) => ({ X: p.x * scale, Y: p.y * scale }));

        polygons.forEach((polygon) => {
          if (polygon.length === 0) return;

          const polygonPoints: ClipperLib.Path = polygon.map((p) => ({
            X: p.x * scale,
            Y: p.y * scale,
          }));

          const clipper = new ClipperLib.Clipper();
          clipper.AddPath(shapePoints, ClipperLib.PolyType.ptSubject, true);
          const solution: ClipperLib.Paths = [];
          clipper.AddPath(polygonPoints, ClipperLib.PolyType.ptClip, true);
          clipper.Execute(ClipperLib.ClipType.ctIntersection, solution);

          if (solution.length === 0) return;

          solution.forEach((ring) => {
            const trimmedPolygon = ring.map(
              (point) => new THREE.Vector2(point.X / scale, point.Y / scale),
            );

            if (trimmedPolygon.length >= 3) {
              // Ensure valid polygon
              // Scale the hole coordinates inward (we always want the hole to be smaller/inside of the shape)
              const center = trimmedPolygon
                .reduce((sum, p) => sum.add(p), new THREE.Vector2(0, 0))
                .multiplyScalar(1 / trimmedPolygon.length);
              const insetPolygon = trimmedPolygon.map((p) =>
                p.clone().sub(center).multiplyScalar(holeInset).add(center),
              );

              const hole = new THREE.Path().setFromPoints(insetPolygon);
              shape.holes.push(hole);

              // const debugShape = new THREE.Shape();
              // debugShape.curves = hole.curves;
              // debugDrawShape(debugShape, 0xff00ff); // Debug color for holes
            } else {
              console.warn("Invalid hole shape", trimmedPolygon);
            }
          });

          //debugDrawShape(shape, 0x0000ff); // Debug color for shapes
        });
      });
    };

    const debugDrawShape = (shape: THREE.Shape, color: number) => {
      const points = shape.getPoints();
      points.push(points[0]);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color });
      const line = new THREE.Line(geometry, material);
      line.position.z = 0.001;
      scene.add(line);

      shape.holes.forEach((hole, index) => {
        const holePoints = hole.getPoints();
        const holeGeometry = new THREE.BufferGeometry().setFromPoints(
          holePoints,
        );
        const holeMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const holeLine = new THREE.Line(holeGeometry, holeMaterial);
        holeLine.position.z = 0.001;
        scene.add(holeLine);
      });
    };

    for (let i = 0; i < Object.keys(walls).length; i++) {
      const wall = walls[Object.keys(walls)[i]];

      const innerShapes = createShapes(
        filledLines[i],
        "inner",
        wall.lineWidth as number,
        wall.wallWidth as number,
      );
      const leftShapes = createShapes(
        leftLines[i],
        "left",
        wall.lineWidth as number,
        wall.wallWidth as number,
      );
      const rightShapes = createShapes(
        rightLines[i],
        "right",
        wall.lineWidth as number,
        wall.wallWidth as number,
      );
      const endCapsShapes = createShapes(
        endCaps[i],
        "endCaps",
        wall.lineWidth as number,
        wall.wallWidth as number,
      );

      const clippingPolygons = wall.clippingPolygons.map(
        (polygon) => polygon.polygon,
      );
      addHolesToShapes(innerShapes, clippingPolygons);
      addHolesToShapes(leftShapes, clippingPolygons);
      addHolesToShapes(rightShapes, clippingPolygons);
      addHolesToShapes(endCapsShapes, clippingPolygons);

      const innerGeometry = new THREE.ShapeGeometry(innerShapes);
      const leftLineGeometry = new THREE.ShapeGeometry(leftShapes);
      const rightLineGeometry = new THREE.ShapeGeometry(rightShapes);
      const endCapsGeometry = new THREE.ShapeGeometry(endCapsShapes);

      const customFillColor =
        walls[Object.keys(walls)[i]].fillColor || fillColor;
      const customLineColor =
        walls[Object.keys(walls)[i]].lineColor || lineColor;
      const filledMaterial = new THREE.MeshBasicMaterial({
        color: customFillColor,
        side: THREE.DoubleSide,
      });
      const lineMaterial = new THREE.MeshBasicMaterial({
        color: customLineColor,
        side: THREE.DoubleSide,
      });

      const innerMesh = new THREE.Mesh(innerGeometry, filledMaterial);
      const leftLineMesh = new THREE.Mesh(leftLineGeometry, lineMaterial);
      const rightLineMesh = new THREE.Mesh(rightLineGeometry, lineMaterial);
      const endCapsMesh = new THREE.Mesh(endCapsGeometry, lineMaterial);

      innerMesh.userData.type = "fill";
      leftLineMesh.userData.type = "line";
      rightLineMesh.userData.type = "line";
      endCapsMesh.userData.type = "line";

      const mesh = new THREE.Mesh();
      mesh.add(innerMesh);
      mesh.add(leftLineMesh);
      mesh.add(rightLineMesh);
      mesh.add(endCapsMesh);

      mesh.userData.index = i;
      mesh.userData.id = Object.keys(walls)[i];
      newMeshWalls.push(mesh);
    }

    meshWalls.forEach((mesh) => {
      disposeMesh(mesh);
    });

    setMeshWalls(newMeshWalls);
    invalidate();

    return () => {
      newMeshWalls.forEach((mesh) => {
        disposeMesh(mesh);
      });
    };
  }, [walls, wallWidth, lineWidth, lineColor, fillColor, disposeMesh, scene]);

  const onWallPointerEnter = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      let updated = false;
      meshWalls[index].children.forEach((child: any) => {
        if (
          child.userData.type === "line" &&
          child.material.color.getHex() !== hoverColor
        ) {
          child.material.color.set(hoverColor);
          updated = true;
        }
      });
      if (updated) {
        invalidate();
      }
    },
    [hoverColor, meshWalls],
  );

  const onWallPointerLeave = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      let updated = false;
      meshWalls[index].children.forEach((child: any) => {
        if (child.userData.type === "line") {
          const customLineColor =
            walls[Object.keys(walls)[index]].lineColor || lineColor;
          if (child.material.color.getHex() !== customLineColor) {
            child.material.color.set(customLineColor);
            updated = true;
          }
        }
      });
      if (updated) {
        invalidate();
      }
    },
    [meshWalls, walls, lineColor],
  );

  const projectToWorld = useCallback(
    (clientX: number, clientY: number): [number, number] => {
      const raycaster = new THREE.Raycaster();
      const rect = gl.domElement.getBoundingClientRect();
      const x = ((clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

      const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
      const intersectPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectPoint);

      return [intersectPoint.x, intersectPoint.y];
    },
    [gl, camera],
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (dragWall.current !== null && dragOffset.current) {
        if (!dragging) setDragging(true);
        const [newX, newY] = projectToWorld(e.clientX, e.clientY);
        const [startOffsetX, startOffsetY, endOffsetX, endOffsetY] =
          dragOffset.current;
        const wall = dragWall.current;
        const tempWalls = { ...walls };

        let delta;
        if (dragHandle.current.handle === "start") {
          delta = new THREE.Vector2(
            newX - startOffsetX,
            newY - startOffsetY,
          ).sub(wall.start);
          tempWalls[wall.id].start.add(delta);
          // Break free connections to the start of the wall
          wall.connections.forEach((c) => {
            tempWalls[c.id].connections = tempWalls[c.id].connections.filter(
              (c2) =>
                c2.id !== wall.id || c2.targetPosition !== WallConnectionStart,
            );
            tempWalls[c.id].clippingPolygons = tempWalls[
              c.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== wall.id);
            tempWalls[c.id].cutouts = tempWalls[c.id].cutouts.filter(
              (c2) => c2.belongsTo !== wall.id,
            );
            tempWalls[wall.id].clippingPolygons = tempWalls[
              wall.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== c.id);
            tempWalls[wall.id].cutouts = tempWalls[wall.id].cutouts.filter(
              (c2) => c2.belongsTo !== c.id,
            );
          });
          tempWalls[wall.id].connections = tempWalls[
            wall.id
          ].connections.filter((c) => c.sourcePosition !== WallConnectionStart);
          // Snap to other walls
          const wallStart = new THREE.Vector2(
            tempWalls[wall.id].start.x,
            tempWalls[wall.id].start.y,
          );
          Object.values(tempWalls).forEach((w) => {
            if (w.id !== wall.id) {
              const otherLine = new THREE.Line3(
                new THREE.Vector3(w.start.x, w.start.y, 0),
                new THREE.Vector3(w.end.x, w.end.y, 0),
              );
              const closestStart = new THREE.Vector3();
              otherLine.closestPointToPoint(
                new THREE.Vector3(wallStart.x, wallStart.y, 0),
                true,
                closestStart,
              );
              if (
                wallStart.distanceTo(
                  new THREE.Vector2(closestStart.x, closestStart.y),
                ) < SNAP_THRESHOLD
              ) {
                tempWalls[wall.id].start.set(closestStart.x, closestStart.y);
                const connectionPos = w.start.distanceTo(
                  new THREE.Vector2(closestStart.x, closestStart.y),
                );
                tempWalls[wall.id].connections.push({
                  id: w.id,
                  sourcePosition: WallConnectionStart,
                  targetPosition: connectionPos,
                });
                tempWalls[w.id].connections.push({
                  id: wall.id,
                  sourcePosition: connectionPos,
                  targetPosition: WallConnectionStart,
                });
              }
            }
          });
        } else if (dragHandle.current.handle === "end") {
          delta = new THREE.Vector2(newX - endOffsetX, newY - endOffsetY).sub(
            wall.end,
          );
          tempWalls[wall.id].end.add(delta);
          // Break free connections to the end of this wall
          wall.connections.forEach((c) => {
            tempWalls[c.id].connections = tempWalls[c.id].connections.filter(
              (c2) =>
                c2.id !== wall.id || c2.targetPosition !== WallConnectionEnd,
            );
            tempWalls[c.id].clippingPolygons = tempWalls[
              c.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== wall.id);
            tempWalls[c.id].cutouts = tempWalls[c.id].cutouts.filter(
              (c2) => c2.belongsTo !== wall.id,
            );
            tempWalls[wall.id].clippingPolygons = tempWalls[
              wall.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== c.id);
            tempWalls[wall.id].cutouts = tempWalls[wall.id].cutouts.filter(
              (c2) => c2.belongsTo !== c.id,
            );
          });
          tempWalls[wall.id].connections = tempWalls[
            wall.id
          ].connections.filter((c) => c.sourcePosition !== WallConnectionEnd);
          // Snap to other walls
          const wallEnd = new THREE.Vector2(
            tempWalls[wall.id].end.x,
            tempWalls[wall.id].end.y,
          );
          Object.values(tempWalls).forEach((w) => {
            if (w.id !== wall.id) {
              const otherLine = new THREE.Line3(
                new THREE.Vector3(w.start.x, w.start.y, 0),
                new THREE.Vector3(w.end.x, w.end.y, 0),
              );
              const closestEnd = new THREE.Vector3();
              otherLine.closestPointToPoint(
                new THREE.Vector3(wallEnd.x, wallEnd.y, 0),
                true,
                closestEnd,
              );
              if (
                wallEnd.distanceTo(
                  new THREE.Vector2(closestEnd.x, closestEnd.y),
                ) < SNAP_THRESHOLD
              ) {
                tempWalls[wall.id].end.set(closestEnd.x, closestEnd.y);
                const connectionPos = w.start.distanceTo(
                  new THREE.Vector2(closestEnd.x, closestEnd.y),
                );
                tempWalls[wall.id].connections.push({
                  id: w.id,
                  sourcePosition: WallConnectionEnd,
                  targetPosition: connectionPos,
                });
                tempWalls[w.id].connections.push({
                  id: wall.id,
                  sourcePosition: connectionPos,
                  targetPosition: WallConnectionEnd,
                });
              }
            }
          });
        } else if (dragHandle.current.handle === "middle") {
          const midPoint = new THREE.Vector2(
            (wall.start.x + wall.end.x) / 2,
            (wall.start.y + wall.end.y) / 2,
          );
          delta = new THREE.Vector2(
            newX - startOffsetX,
            newY - startOffsetY,
          ).sub(midPoint);
          const wallDirection = new THREE.Vector2()
            .subVectors(wall.end, wall.start)
            .normalize();
          const perpendicular = new THREE.Vector2(
            -wallDirection.y,
            wallDirection.x,
          );
          const distance = delta.dot(perpendicular);
          delta = perpendicular.clone().multiplyScalar(distance);
          tempWalls[wall.id].start.add(delta);
          tempWalls[wall.id].end.add(delta);
        } else if (dragHandle.current.handle === "wall") {
          const deltaStart = new THREE.Vector2(
            newX - startOffsetX,
            newY - startOffsetY,
          ).sub(wall.start);
          const deltaEnd = new THREE.Vector2(
            newX - endOffsetX,
            newY - endOffsetY,
          ).sub(wall.end);
          tempWalls[wall.id].start.add(deltaStart);
          tempWalls[wall.id].end.add(deltaEnd);
          delta = deltaStart;
          wall.connections.forEach((c) => {
            tempWalls[c.id].connections = tempWalls[c.id].connections.filter(
              (c2) => c2.id !== wall.id,
            );
            tempWalls[c.id].cutouts = tempWalls[c.id].cutouts.filter(
              (c2) => c2.belongsTo !== wall.id,
            );
            tempWalls[c.id].clippingPolygons = tempWalls[
              c.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== wall.id);
            tempWalls[wall.id].cutouts = tempWalls[wall.id].cutouts.filter(
              (c2) => c2.belongsTo !== c.id,
            );
            tempWalls[wall.id].clippingPolygons = tempWalls[
              wall.id
            ].clippingPolygons.filter((c2) => c2.belongsTo !== c.id);
          });
          // Break free and remove all connections
          tempWalls[wall.id].connections = [];
          // Snap to other walls
          const wallStart = new THREE.Vector2(
            tempWalls[wall.id].start.x,
            tempWalls[wall.id].start.y,
          );
          const wallEnd = new THREE.Vector2(
            tempWalls[wall.id].end.x,
            tempWalls[wall.id].end.y,
          );
          Object.values(tempWalls).forEach((w) => {
            if (w.id !== wall.id) {
              const otherLine = new THREE.Line3(
                new THREE.Vector3(w.start.x, w.start.y, 0),
                new THREE.Vector3(w.end.x, w.end.y, 0),
              );
              const closestStart = new THREE.Vector3();
              const closestEnd = new THREE.Vector3();
              otherLine.closestPointToPoint(
                new THREE.Vector3(wallStart.x, wallStart.y, 0),
                true,
                closestStart,
              );
              otherLine.closestPointToPoint(
                new THREE.Vector3(wallEnd.x, wallEnd.y, 0),
                true,
                closestEnd,
              );
              if (
                wallStart.distanceTo(
                  new THREE.Vector2(closestStart.x, closestStart.y),
                ) < SNAP_THRESHOLD
              ) {
                tempWalls[wall.id].start.set(closestStart.x, closestStart.y);
                const connectionPos = w.start.distanceTo(
                  new THREE.Vector2(closestStart.x, closestStart.y),
                );
                tempWalls[wall.id].connections.push({
                  id: w.id,
                  sourcePosition: WallConnectionStart,
                  targetPosition: connectionPos,
                });
                tempWalls[w.id].connections.push({
                  id: wall.id,
                  sourcePosition: connectionPos,
                  targetPosition: WallConnectionStart,
                });
              }
              if (
                wallEnd.distanceTo(
                  new THREE.Vector2(closestEnd.x, closestEnd.y),
                ) < SNAP_THRESHOLD
              ) {
                tempWalls[wall.id].end.set(closestEnd.x, closestEnd.y);
                const connectionPos = w.start.distanceTo(
                  new THREE.Vector2(closestEnd.x, closestEnd.y),
                );
                tempWalls[wall.id].connections.push({
                  id: w.id,
                  sourcePosition: WallConnectionEnd,
                  targetPosition: connectionPos,
                });
                tempWalls[w.id].connections.push({
                  id: wall.id,
                  sourcePosition: connectionPos,
                  targetPosition: WallConnectionEnd,
                });
              }
            }
          });
        }
        updateDragHandles(dragHandle.current?.handle || "", tempWalls[wall.id]);

        if (delta) {
          updateWallConnections(
            tempWalls,
            wall,
            dragHandle.current?.handle || null,
          );
        }
        setWalls(tempWalls);
      }
    },
    [dragging, projectToWorld, setWalls, updateDragHandles, walls],
  );

  useEffect(() => {
    const handlePointerUp = (e: PointerEvent) => {
      dragWall.current = null;
      dragOffset.current = null;
      dragHandle.current.handle = "";
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
    };

    if (dragWall.current !== null) {
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
    }

    return () => {
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
    };
  }, [handlePointerMove, gl.domElement]);

  const handlePointerUp = useCallback(
    (e: PointerEvent) => {
      floorplannerStore.setDirty();
      dragWall.current = null;
      dragOffset.current = null;
      dragHandle.current.handle = "";
      setDragging(false);
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
    },
    [gl.domElement, handlePointerMove],
  );

  const onWallPointerDown = useCallback(
    (event: ThreeEvent<PointerEvent>, index: number) => {
      event.stopPropagation();
      const wall = walls[Object.keys(walls)[index]];
      dragWall.current = wall;
      updateDragHandles(dragHandle.current?.handle || "wall", wall);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY);
      const startOffsetX = worldX - wall.start.x;
      const startOffsetY = worldY - wall.start.y;
      const endOffsetX = worldX - wall.end.x;
      const endOffsetY = worldY - wall.end.y;
      dragOffset.current = [startOffsetX, startOffsetY, endOffsetX, endOffsetY];
      setSelectedWallIndex(index);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
    },
    [
      walls,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
    ],
  );

  const onWallSelectionPointerDown = useCallback(
    (
      event: ThreeEvent<PointerEvent>,
      index: number,
      handle: "start" | "end" | "middle",
    ) => {
      event.stopPropagation();
      const wall = walls[Object.keys(walls)[index]];
      dragWall.current = wall;
      updateDragHandles(handle, wall);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY);
      let offsetX, offsetY;

      if (handle === "middle") {
        const midpointX = (wall.start.x + wall.end.x) / 2;
        const midpointY = (wall.start.y + wall.end.y) / 2;
        offsetX = worldX - midpointX;
        offsetY = worldY - midpointY;
      } else {
        offsetX = worldX - (handle === "start" ? wall.start.x : wall.end.x);
        offsetY = worldY - (handle === "start" ? wall.start.y : wall.end.y);
      }

      dragOffset.current = [offsetX, offsetY, offsetX, offsetY];
      setSelectedWallIndex(index);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
    },
    [
      walls,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
    ],
  );

  const handleCanvasClick = useCallback((event: PointerEvent) => {
    setSelectedWallIndex(null);
  }, []);

  useEffect(() => {
    gl.domElement.addEventListener("pointerdown", handleCanvasClick);
    return () => {
      gl.domElement.removeEventListener("pointerdown", handleCanvasClick);
    };
  }, [gl.domElement, handleCanvasClick]);

  const calculateWallPositionAndRotation = (start: Point, end: Point) => {
    const position = new THREE.Vector3(
      (start.x + end.x) / 2,
      (start.y + end.y) / 2,
      0.001, // Slightly above the floor
    );

    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    return { position, rotation: angle };
  };

  return (
    <group>
      {meshWalls.map((wall, index) => (
        <group
          key={wall.userData.id}
          onPointerEnter={
            !dragging
              ? (event: ThreeEvent<PointerEvent>) =>
                  onWallPointerEnter(event, index)
              : undefined
          }
          onPointerLeave={
            !dragging
              ? (event: ThreeEvent<PointerEvent>) =>
                  onWallPointerLeave(event, index)
              : undefined
          }
          onPointerDown={(event: ThreeEvent<PointerEvent>) =>
            onWallPointerDown(event, index)
          }
        >
          <primitive object={wall} />
          {selectedWallIndex === index && (
            <>
              {(!dragging || dragHandle.current?.handle === "middle") && (
                <group
                  onPointerDown={(event: ThreeEvent<PointerEvent>) =>
                    onWallSelectionPointerDown(event, index, "middle")
                  }
                >
                  <mesh
                    position={calculateWallPositionAndRotation(
                      walls[Object.keys(walls)[index]].start,
                      walls[Object.keys(walls)[index]].end,
                    ).position.add(new THREE.Vector3(0, 0, -handleSize / 2))}
                    rotation={[
                      0,
                      0,
                      calculateWallPositionAndRotation(
                        walls[Object.keys(walls)[index]].start,
                        walls[Object.keys(walls)[index]].end,
                      ).rotation,
                    ]}
                  >
                    <primitive
                      object={createMiddleDragHandle(
                        walls[Object.keys(walls)[index]],
                        wallWidth,
                      )}
                    />
                    <meshBasicMaterial
                      color="darkblue"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                  <mesh
                    position={
                      calculateWallPositionAndRotation(
                        walls[Object.keys(walls)[index]].start,
                        walls[Object.keys(walls)[index]].end,
                      ).position
                    }
                    rotation={[
                      0,
                      0,
                      calculateWallPositionAndRotation(
                        walls[Object.keys(walls)[index]].start,
                        walls[Object.keys(walls)[index]].end,
                      ).rotation,
                    ]}
                  >
                    <planeGeometry
                      args={[
                        handleWidth,
                        (walls[Object.keys(walls)[index]].wallWidth ||
                          wallWidth) +
                          handleSize * 1.5,
                      ]}
                    />
                    <meshBasicMaterial
                      color="white"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                </group>
              )}
              {(!dragging || dragHandle.current?.handle === "start") && (
                <group
                  onPointerDown={(event: ThreeEvent<PointerEvent>) =>
                    onWallSelectionPointerDown(event, index, "start")
                  }
                >
                  <mesh position={dragHandle.current?.startPosition}>
                    <torusGeometry args={[handleSize, tubeRadius, 16, 100]} />
                    <meshBasicMaterial
                      color="darkblue"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                  <mesh
                    position={dragHandle.current?.startPosition.add(
                      new THREE.Vector3(0, 0, 0.001),
                    )}
                  >
                    <circleGeometry args={[handleSize + tubeRadius, 32]} />
                    <meshBasicMaterial
                      color="white"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                </group>
              )}
              {(!dragging || dragHandle.current?.handle === "end") && (
                <group
                  onPointerDown={(event: ThreeEvent<PointerEvent>) =>
                    onWallSelectionPointerDown(event, index, "end")
                  }
                >
                  <mesh position={dragHandle.current?.endPosition}>
                    <torusGeometry args={[handleSize, tubeRadius, 16, 100]} />
                    <meshBasicMaterial
                      color="darkblue"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                  <mesh
                    position={dragHandle.current?.endPosition.add(
                      new THREE.Vector3(0, 0, 0.001),
                    )}
                  >
                    <circleGeometry args={[handleSize + tubeRadius, 32]} />
                    <meshBasicMaterial
                      color="white"
                      transparent
                      opacity={0.8}
                    />
                  </mesh>
                </group>
              )}
            </>
          )}
        </group>
      ))}
    </group>
  );
});

export default WallStructure;
export type { Point };
