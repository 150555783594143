import { useContext, useEffect, useState } from "react";
import { generalStoreContext } from "../store/generalStore";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const Login = observer(() => {
  const store = useContext(generalStoreContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    setIsAuthenticated(store.isAuthenticated);
  }, [store.client, store.isAuthenticated]);

  if (isAuthenticated) {
    // Redirect to home page if user is authenticated
    //return <Navigate to='/' replace />;
  }
  return (
    <>
      <h1>You need to login</h1>
      <h2>{isAuthenticated ? store.userId : "anonymous"}</h2>
    </>
  );
});

export default Login;
