import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./routes/Main";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  fromPromise,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./routes/Error";
import PropertyPanel from "./components/PropertyPanel";
import Login from "./routes/Login";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URI,
  credentials: "include",
});

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const refreshTokens = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVER_URI ?? "", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        query: `
          mutation refreshTokens {
            refreshTokens {
              accessToken
              refreshToken
            }
          }
        `,
      }),
    });
    const result = await response.json();
    if (result.data && result.data.refreshTokens) {
      document.cookie = `auth_token=${result.data.refreshTokens.accessToken}; refresh_token=${result.data.refreshTokens.refreshToken}; path=/; secure; HttpOnly;`;
      return result.data.refreshTokens;
    }
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    // redirect to login page since refresh token is invalid (we are not in a React component)
    window.location.href = "/login?redirect=" + window.location.pathname;
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (process.env.NODE_ENV === "development") {
        console.log({ graphQLErrors });
      }
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case "UNAUTHENTICATED":
            if (!isRefreshing) {
              isRefreshing = true;
              return fromPromise(
                refreshTokens()
                  .then((newAccessToken) => {
                    resolvePendingRequests();
                    return newAccessToken;
                  })

                  .catch(() => {
                    pendingRequests = [];
                    return;
                  })

                  .finally(() => {
                    isRefreshing = false;
                  }),
              )
                .filter((value) => Boolean(value))
                .flatMap(() => forward(operation));
            }
            return fromPromise(
              new Promise<void>((resolve) => {
                pendingRequests.push(() => resolve());
              }),
            )
              .filter((value) => Boolean(value))
              .flatMap(() => forward(operation));
        }
      }
    }
    if (networkError) {
      console.error("Network error:", networkError);
    }
  },
);

const authLink = setContext((_, { headers }) => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_token="));
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.split("=")[1]}` : "",
    },
  };
});

const httpLinkAuth = ApolloLink.from([errorLink, authLink, httpLink as any]);

export const client = new ApolloClient({
  link: httpLinkAuth,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main client={client} />}>
            <Route path="property/:id" element={<PropertyPanel />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
