import React, { useState, useEffect, ReactNode } from "react";
import { Canvas } from "@react-three/fiber";
import { DoorType, WindowType } from "../../types/wallTypes";
import Grid from "./Grid";
import { OrbitControls } from "@react-three/drei";
import WallStructure from "./WallStructure";
import { editorStoreContext } from "../../store/editorStore";
import Door from "./Door";
import Window from "./Window";
import { Stats } from "@react-three/drei";
import {
  FloorplannerStoreContext,
  floorplannerStore,
} from "../../store/floorplannerStore";
import { observer } from "mobx-react-lite";

interface StoreProviderProps {
  children: ReactNode;
}

export const FloorplannerStoreProvider: React.FC<StoreProviderProps> = ({
  children,
}) => {
  return (
    <FloorplannerStoreContext.Provider value={floorplannerStore}>
      {children}
    </FloorplannerStoreContext.Provider>
  );
};

const FloorPlan: React.FC = observer(() => {
  const editorStore = React.useContext(editorStoreContext);
  const { saveToCloud } = React.useContext(FloorplannerStoreContext);

  const [doors, setDoors] = useState<DoorType[]>([
    { id: 1, position: [2.5, 0], attachedTo: 1, relativePosition: 0.5 },
  ]);

  const [windows, setWindows] = useState<WindowType[]>([
    { id: 1, position: [5, 2.5], attachedTo: 2, relativePosition: 0.5 },
  ]);

  //Autosave document at interval 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      saveToCloud(false, false);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Canvas
      camera={{ position: [0, 0, 20], fov: 40 }}
      frameloop="demand"
      style={{ userSelect: "none" }}
      gl={{ localClippingEnabled: true }}
    >
      <Grid />
      {editorStore.view3D && <OrbitControls />}
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <FloorplannerStoreProvider>
        <WallStructure />
        <Door
          door={doors[0]}
          onDragStart={(door, offset) => {}}
          onDragEnd={() => {}}
          onDrag={(newPosition) => {
            setDoors([{ ...doors[0], position: newPosition }]);
          }}
        />
        <Window
          window={windows[0]}
          onDragStart={(window, offset) => {}}
          onDragEnd={() => {}}
          onDrag={(newPosition) => {
            setWindows([{ ...windows[0], position: newPosition }]);
          }}
        />
      </FloorplannerStoreProvider>
      {/* <Stats /> */}
    </Canvas>
  );
});

export default FloorPlan;
