import * as THREE from "three";
import {
  WallConnectionEnd,
  WallConnectionStart,
  WallType,
} from "../../types/wallTypes";

export const updateWallConnections = (
  tempWalls: { [x: string]: WallType },
  wall: WallType,
  dragHandle: string | null,
  updatedWalls?: string[],
) => {
  const newUpdatedWalls = updatedWalls || [];
  let updated = false;
  wall.connections.forEach((c) => {
    if (c.sourcePosition === WallConnectionStart) {
      // A connection from the start of the wall
      if (dragHandle === "start" || dragHandle === "middle") {
        if (
          c.targetPosition === WallConnectionStart &&
          (tempWalls[c.id].start.x !== wall.start.x ||
            tempWalls[c.id].start.y !== wall.start.y)
        ) {
          tempWalls[c.id].start = new THREE.Vector2(wall.start.x, wall.start.y);
          updated = true;
        } else if (
          c.targetPosition === WallConnectionEnd &&
          (tempWalls[c.id].end.x !== wall.start.x ||
            tempWalls[c.id].end.y !== wall.start.y)
        ) {
          tempWalls[c.id].end = new THREE.Vector2(wall.start.x, wall.start.y);
          updated = true;
        }
      }
    } else if (c.sourcePosition === WallConnectionEnd) {
      // A connection from the end of the wall
      if (dragHandle === "end" || dragHandle === "middle") {
        if (
          c.targetPosition === WallConnectionStart &&
          (tempWalls[c.id].start.x !== wall.end.x ||
            tempWalls[c.id].start.y !== wall.end.y)
        ) {
          tempWalls[c.id].start = new THREE.Vector2(wall.end.x, wall.end.y);
          updated = true;
        } else if (
          c.targetPosition === WallConnectionEnd &&
          (tempWalls[c.id].end.x !== wall.end.x ||
            tempWalls[c.id].end.y !== wall.end.y)
        ) {
          tempWalls[c.id].end = new THREE.Vector2(wall.end.x, wall.end.y);
          updated = true;
        }
      }
    } else {
      // A connection from a custom position of the wall
      const wallDirection = new THREE.Vector2()
        .subVectors(wall.end, wall.start)
        .normalize();
      const newPosition = new THREE.Vector2().addVectors(
        wall.start,
        wallDirection.clone().multiplyScalar(c.sourcePosition),
      );
      if (
        c.targetPosition === WallConnectionStart &&
        (tempWalls[c.id].start.x !== newPosition.x ||
          tempWalls[c.id].start.y !== newPosition.y)
      ) {
        tempWalls[c.id].start = newPosition;
        updated = true;
      } else if (
        c.targetPosition === WallConnectionEnd &&
        (tempWalls[c.id].end.x !== newPosition.x ||
          tempWalls[c.id].end.y !== newPosition.y)
      ) {
        tempWalls[c.id].end = newPosition;
        updated = true;
      }
    }
  });
  // Check if any connection has been updated, and if so, recursively update connections of connected walls
  if (updated) {
    wall.connections.forEach((c) => {
      if (!newUpdatedWalls.includes(c.id)) {
        newUpdatedWalls.push(c.id);
        updateWallConnections(
          tempWalls,
          tempWalls[c.id],
          null,
          newUpdatedWalls,
        );
      }
    });
  }
};
