import React from "react";
import { WindowType } from "../../types/wallTypes";
import DraggableObject from "./DraggableObject";

interface WindowProps {
  window: WindowType;
  onDragStart: (window: WindowType, offset: [number, number]) => void;
  onDragEnd: () => void;
  onDrag: (newPosition: [number, number]) => void;
}

const Window: React.FC<WindowProps> = ({
  window,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  return (
    <DraggableObject
      position={window.position}
      onDragStart={(offset) => onDragStart(window, offset)}
      onDragEnd={onDragEnd}
      onDrag={onDrag}
      color="blue"
      hoverColor="lightblue"
      selectable={true}
    >
      <mesh>
        <boxGeometry args={[0.1, 0.8, 0.1]} />
        <meshStandardMaterial color="blue" />
      </mesh>
    </DraggableObject>
  );
};

export default Window;
