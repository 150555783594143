import { useEffect } from "react";
import { useThree } from "react-three-fiber";
import * as THREE from "three"; // Add this line to import the 'THREE' object

const Grid: React.FC = () => {
  const { scene } = useThree();

  useEffect(() => {
    const size = 100;
    const divisions = 100;

    const gridHelperX = new THREE.GridHelper(size, divisions);
    scene.add(gridHelperX);

    const gridHelperY = new THREE.GridHelper(size, divisions);
    gridHelperY.rotation.x = Math.PI / 2;
    scene.add(gridHelperY);

    return () => {
      scene.remove(gridHelperX);
      scene.remove(gridHelperY);
    };
  }, [scene]);

  return null;
};

export default Grid;
