import React, { useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Toolbar from "../components/Toolbar";
import SidebarLeft from "../components/SidebarLeft";
import FloorPlan from "../components/FloorPlan/FloorPlan";
import { generalStoreContext } from "../store/generalStore";
import { FloorplannerStoreContext } from "../store/floorplannerStore";
import { ApolloClient } from "@apollo/client";

const Main: React.FC<{
  client: ApolloClient<any>;
}> = observer(({ client }) => {
  const initializedRef = useRef(false);
  const generalStore = useContext(generalStoreContext);
  const floorplanStore = useContext(FloorplannerStoreContext);

  useEffect(() => {
    if (!initializedRef.current) {
      generalStore.initialize(client);
      floorplanStore.initialize(client);
      initializedRef.current = true;
    }
  }, [client, floorplanStore, generalStore]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        className="toolbar z-10 bg-eggshell"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100wv",
          padding: 10,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Toolbar />
      </div>
      <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <div
          className="sidebar-left bg-eggshell"
          style={{
            display: "flex",
            flexDirection: "column",
            width: 100,
            padding: 10,
            borderRight: "1px solid #ccc",
          }}
        >
          <SidebarLeft />
        </div>
        <div style={{ flex: 1 }}>
          <FloorPlan />
        </div>
        <div
          className="sidebar-right bg-eggshell"
          style={{
            display: "flex",
            flexDirection: "column",
            width: 200,
            padding: 10,
            borderLeft: "1px solid #ccc",
          }}
        >
          <Outlet /> {/* This renders nested routes */}
        </div>
      </div>
    </div>
  );
});

export default Main;
