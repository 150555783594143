import React from "react";
import { useEditorStore } from "../store/editorStore";
import { Button, ButtonGroup } from "flowbite-react";
import {
  HiOutlinePlus,
  HiChevronDown,
} from "react-icons/hi";
import boniLogo from "../../src/assets/boni_logo2.png";
import { observer } from "mobx-react-lite";
const Toolbar = observer(() => {
  const editorStore = useEditorStore();

  return (
    <div className="toolbar z-10 flex w-full items-center border-b border-gray-300 bg-eggshell p-2.5">
      {/* Logo section */}
      <div
        className="flex items-center bg-bonigold"
        style={{ width: 52, height: 52 }}
      >
        <img
          src={boniLogo}
          alt="boni logo black"
          className="w-6 lg:w-12"
          style={{ width: 34 }}
        />
        <HiChevronDown className="h-4 w-4" />
      </div>

      {/* Seven buttons group */}
      <div className="ml-8 inline-flex h-[34px] w-[490px] items-center justify-start gap-px">
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V9M9 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9M9 3V21M3 9V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H9M3 9H21M21 9V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H9"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div />
          </div>
        </div>
        <div className="bg-stone-500 inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_477_10936)">
                <rect
                  width="34"
                  height="34"
                  rx="2"
                  fill=""
                  data-originalfillcolor="#fff"
                ></rect>
                <path
                  d="M25 13H9C7.89543 13 7 13.8954 7 15V19C7 20.1046 7.89543 21 9 21H25C26.1046 21 27 20.1046 27 19V15C27 13.8954 26.1046 13 25 13Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M9 16C9 16.2761 9.22386 16.5 9.5 16.5C9.77614 16.5 10 16.2761 10 16H9ZM9 13V16H10V13H9Z"
                  fill="black"
                ></path>
                <path
                  d="M11.5 18C11.5 18.2761 11.7239 18.5 12 18.5C12.2761 18.5 12.5 18.2761 12.5 18H11.5ZM11.5 13V18H12.5V13H11.5Z"
                  fill="black"
                ></path>
                <path
                  d="M14 16C14 16.2761 14.2239 16.5 14.5 16.5C14.7761 16.5 15 16.2761 15 16H14ZM14 13V16H15V13H14Z"
                  fill="black"
                ></path>
                <path
                  d="M16.5 18C16.5 18.2761 16.7239 18.5 17 18.5C17.2761 18.5 17.5 18.2761 17.5 18H16.5ZM16.5 13V18H17.5V13H16.5Z"
                  fill="black"
                ></path>
                <path
                  d="M19 16C19 16.2761 19.2239 16.5 19.5 16.5C19.7761 16.5 20 16.2761 20 16H19ZM19 13V16H20V13H19Z"
                  fill="black"
                ></path>
                <path
                  d="M21.5 18C21.5 18.2761 21.7239 18.5 22 18.5C22.2761 18.5 22.5 18.2761 22.5 18H21.5ZM21.5 13V18H22.5V13H21.5Z"
                  fill="black"
                ></path>
                <path
                  d="M24 16C24 16.2761 24.2239 16.5 24.5 16.5C24.7761 16.5 25 16.2761 25 16H24ZM24 13V16H25V13H24Z"
                  fill="black"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_477_10936">
                  <rect width="34" height="34" rx="2" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 8H4.5C4.10218 8 3.72064 8.1475 3.43934 8.41005C3.15804 8.6726 3 9.0287 3 9.4V20.6C3 20.9713 3.15804 21.3274 3.43934 21.5899C3.72064 21.8525 4.10218 22 4.5 22H13.5C13.8978 22 14.2794 21.8525 14.5607 21.5899C14.842 21.3274 15 20.9713 15 20.6V18"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 2H10.5C10.1022 2 9.72064 2.15804 9.43934 2.43934C9.15804 2.72064 9 3.10218 9 3.5V15.5C9 15.8978 9.15804 16.2794 9.43934 16.5607C9.72064 16.842 10.1022 17 10.5 17H19.5C19.8978 17 20.2794 16.842 20.5607 16.5607C20.842 16.2794 21 15.8978 21 15.5V6.5L16.5 2Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 2V7H21"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="crop" clipPath="url(#clip0_477_10946)">
                <path
                  id="Vector"
                  d="M6.13 1L6 16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H23"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M1 6.13L16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V23"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_477_10946">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <div className="flex items-center justify-center px-[5px]">
              <div className="font-['Gloock'] text-xl font-normal text-black">
                T
              </div>
            </div>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="copy">
                <path
                  id="Vector"
                  d="M17.1538 13L20.6154 13C20.9826 13 21.3348 13.1459 21.5945 13.4055C21.8541 13.6652 22 14.0174 22 14.3846L22 20.6154C22 20.9826 21.8541 21.3348 21.5945 21.5945C21.3348 21.8541 20.9826 22 20.6154 22L14.3846 22C14.0174 22 13.6652 21.8541 13.4055 21.5945C13.1459 21.3348 13 20.9826 13 20.6154L13 17.1538"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M6.84615 11L3.38462 11C3.01739 11 2.66521 10.8541 2.40554 10.5945C2.14588 10.3348 2 9.98261 2 9.61538L2 3.38462C2 3.01739 2.14588 2.66521 2.40554 2.40554C2.66521 2.14588 3.01739 2 3.38462 2L9.61539 2C9.98261 2 10.3348 2.14588 10.5945 2.40554C10.8541 2.66521 11 3.01739 11 3.38462L11 6.84615"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="Group 54">
                  <path
                    id="Vector_3"
                    d="M8.53846 17L15.4615 17C16.3112 17 17 16.3112 17 15.4615L17 8.53846C17 7.68879 16.3112 7 15.4615 7L8.53846 7C7.68879 7 7 7.68879 7 8.53846L7 15.4615C7 16.3112 7.68879 17 8.53846 17Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M7.84615 18.5L16.1538 18.5C17.4496 18.5 18.5 17.4496 18.5 16.1538L18.5 7.84615C18.5 6.55041 17.4496 5.5 16.1538 5.5L7.84615 5.5C6.55041 5.5 5.5 6.55041 5.5 7.84615L5.5 16.1538C5.5 17.4496 6.55041 18.5 7.84615 18.5Z"
                    stroke="#F5F5F5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 9L20 9C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11L22 20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22L11 22C10.4696 22 9.96086 21.7893 9.58579 21.4142C9.21071 21.0391 9 20.5304 9 20L9 15"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 15L13 15C14.1046 15 15 14.1046 15 13L15 4C15 2.89543 14.1046 2 13 2L4 2C2.89543 2 2 2.89543 2 4L2 13C2 14.1046 2.89543 15 4 15Z"
                fill="black"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.30769 16.5L13.6923 16.5C15.243 16.5 16.5 15.243 16.5 13.6923L16.5 3.30769C16.5 1.75705 15.243 0.5 13.6923 0.5L3.30769 0.499999C1.75705 0.499999 0.500001 1.75705 0.500001 3.30769L0.5 13.6923C0.5 15.243 1.75705 16.5 3.30769 16.5Z"
                stroke="#F5F5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V9"
                fill="black"
              />
              <path
                d="M9 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V9"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                fill="#F5F5F5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.6923 7.5H10.3077C8.75705 7.5 7.5 8.75705 7.5 10.3077V20.6923C7.5 22.243 8.75705 23.5 10.3077 23.5H20.6923C22.243 23.5 23.5 22.243 23.5 20.6923V10.3077C23.5 8.75705 22.243 7.5 20.6923 7.5Z"
                stroke="#F5F5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_477_10971)">
                <path
                  d="M8.53846 17L15.4615 17C16.3112 17 17 16.3112 17 15.4615L17 8.53846C17 7.68879 16.3112 7 15.4615 7L8.53846 7C7.68879 7 7 7.68879 7 8.53846L7 15.4615C7 16.3112 7.68879 17 8.53846 17Z"
                  fill="black"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.84615 18.5L16.1538 18.5C17.4496 18.5 18.5 17.4496 18.5 16.1538L18.5 7.84615C18.5 6.55041 17.4496 5.5 16.1538 5.5L7.84615 5.5C6.55041 5.5 5.5 6.55041 5.5 7.84615L5.5 16.1538C5.5 17.4496 6.55041 18.5 7.84615 18.5Z"
                  fill="black"
                  stroke="#F5F5F5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.23077 10L8.76923 10C9.44897 10 10 9.44897 10 8.76923L10 3.23077C10 2.55103 9.44897 2 8.76923 2L3.23077 2C2.55103 2 2 2.55103 2 3.23077L2 8.76923C2 9.44897 2.55103 10 3.23077 10Z"
                  fill="#F5F5F5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.2308 22L20.7692 22C21.449 22 22 21.449 22 20.7692L22 15.2308C22 14.551 21.449 14 20.7692 14L15.2308 14C14.551 14 14 14.551 14 15.2308L14 20.7692C14 21.449 14.551 22 15.2308 22Z"
                  fill="#F5F5F5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.53846 11L9.46154 11C10.3112 11 11 10.3112 11 9.46154L11 2.53846C11 1.68879 10.3112 1 9.46154 1L2.53846 1C1.68879 1 1 1.68879 1 2.53846L0.999999 9.46154C0.999999 10.3112 1.68879 11 2.53846 11Z"
                  stroke="#F5F5F5"
                  strokeWidth="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5385 23L21.4615 23C22.3112 23 23 22.3112 23 21.4615L23 14.5385C23 13.6888 22.3112 13 21.4615 13L14.5385 13C13.6888 13 13 13.6888 13 14.5385L13 21.4615C13 22.3112 13.6888 23 14.5385 23Z"
                  stroke="#F5F5F5"
                  strokeWidth="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_477_10971">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(24 24) rotate(-180)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 10V6.44643C6.99887 5.34487 7.41662 4.28219 8.17216 3.46469C8.9277 2.6472 9.96712 2.1332 11.0886 2.0225C12.2102 1.91179 13.3338 2.21227 14.2413 2.8656C15.1489 3.51894 15.7757 4.47851 16 5.55804"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.3333 10H5.66667C4.74619 10 4 10.9768 4 12.1818V19.8182C4 21.0232 4.74619 22 5.66667 22H17.3333C18.2538 22 19 21.0232 19 19.8182V12.1818C19 10.9768 18.2538 10 17.3333 10Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center gap-2 rounded-sm">
          <div className="inline-flex shrink grow basis-0 items-center justify-center gap-2 border border-eggshell300 px-[5px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 6H5H21"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 11V17"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 11V17"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* Spacer to push the New floorplan button to the center */}
      <div className="flex-grow"></div>

      {/* New floorplan button */}
      <div className="bg-stone-100 inline-flex h-[34px] w-[220px] items-center justify-center rounded-sm border border-eggshell300 pb-[8.31px] pl-[55px] pr-14 pt-[7.69px]">
        <div className="text-zinc-950 font-['DM Sans'] text-sm font-normal">
          New floorplan
        </div>
      </div>

      {/* Spacer to push the 2D/3D and Gallery buttons to the right */}
      <div className="flex-grow"></div>

      {/* 2D/3D view buttons */}
      <ButtonGroup className="mx-2 flex items-center gap-2 rounded-lg border border-eggshell300">
        <Button
          color={editorStore.view3D ? "gray" : "blue"}
          className="px-4"
          onClick={() => editorStore.setView3D(false)}
        >
          2D
        </Button>
        <Button
          color={editorStore.view3D ? "blue" : "gray"}
          className="px-4"
          onClick={() => editorStore.setView3D(true)}
        >
          3D
        </Button>
      </ButtonGroup>

      {/* Gallery post buttons */}
      <div className="inline-flex h-[34px] w-[186px] items-end justify-start gap-[7px]">
        <div className="flex h-[34px] w-[104px] items-center justify-center gap-2 rounded-lg border border-eggshell600 px-3 py-1">
          <div className="text-zinc-950 font-['DM Sans'] text-center text-sm font-normal">
            Gallery post
          </div>
        </div>
        <div className="flex h-[34px] w-[34px] items-center justify-center gap-[6.77px] rounded-lg border border-eggshell600 px-1 py-[6.77px]">
          <div className="relative h-[20.32px] w-[20.32px]">
            <HiOutlinePlus />
          </div>
        </div>
        <div className="flex h-[34px] w-[34px] items-center justify-center gap-2 rounded-lg border border-eggshell600 p-1">
          <div className="text-zinc-950 font-['DM Sans'] pb-1 text-center text-xl font-normal">
            ···
          </div>
        </div>
      </div>
    </div>
  );
});

export default Toolbar;
