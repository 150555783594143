import * as THREE from "three";

export interface DoorType {
  id: number;
  position: [number, number];
  relativePosition?: number;
  attachedTo?: number | null;
  selectable?: boolean;
}

export interface WindowType {
  id: number;
  position: [number, number];
  relativePosition?: number;
  attachedTo?: number | null;
  selectable?: boolean;
}

export type CutoutType = {
  positionFromStart: number;
  length: number;
  // appliesTo is a set of 'left', 'right', 'inner', 'auto-start', 'auto-end'
  appliesTo: Set<string>;
  belongsTo?: string;
};

export type ClippingPlanesType = {
  belongsTo: string;
  planes: THREE.Plane[];
};

export type ClippingPolygonType = {
  belongsTo: string;
  polygon: THREE.Vector2[];
};

export enum LineType {
  single = "single",
  hollow = "hollow",
}

export const WallConnectionEnd = 10000;
export const WallConnectionStart = 0;

export type WallConnectionType = {
  id: string;
  sourcePosition: number;
  targetPosition: number;
};

export type WallType = {
  id: string;
  start: THREE.Vector2;
  end: THREE.Vector2;
  connections: WallConnectionType[];
  cutouts: CutoutType[];
  clippingPolygons: ClippingPolygonType[];
  type: LineType;
  lineWidth: number;
  wallWidth: number;
  selectable: boolean | null;
  selected: boolean | null;
  lineColor: number | null;
  fillColor: number | null;
  hoverColor: number | null;
  lineStyle: string | null;
};

export interface Point {
  x: number;
  y: number;
}

export type LineSideType = "left" | "right" | "inner" | "endcap";

export type WallShapeType = {
  wall: WallType;
  paths: THREE.Vector2[];
  lineSide: LineSideType;
};
