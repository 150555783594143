import { makeAutoObservable } from "mobx";
import * as THREE from "three";
import {
  CutoutType,
  LineType,
  WallType,
  WallConnectionEnd,
  WallConnectionStart,
  ClippingPolygonType,
  WallConnectionType,
} from "../types/wallTypes";
import { createContext, useContext } from "react";
import { ApolloClient, gql } from "@apollo/client";
import {
  EditFloorplanDocument,
  EditFloorplanMutation,
  FloorplanDocument,
  FloorplansDocument,
} from "../gql";

class FloorplannerStore {
  walls: { [key: string]: WallType } = {
    a: {
      id: "a",
      start: new THREE.Vector2(0, 0),
      end: new THREE.Vector2(5, 0),
      cutouts: [
        {
          positionFromStart: 1,
          length: 2,
          appliesTo: new Set(["left", "inner"]),
        },
      ],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "f",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "b",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    },
    b: {
      id: "b",
      start: new THREE.Vector2(5, 0),
      end: new THREE.Vector2(5, 5),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "a",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "c",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
        {
          id: "b1",
          sourcePosition: 1,
          targetPosition: WallConnectionStart,
        },
        {
          id: "g",
          sourcePosition: 2,
          targetPosition: WallConnectionStart,
        },
        {
          id: "h",
          sourcePosition: 0.5,
          targetPosition: WallConnectionEnd,
        },
      ],
      wallWidth: 0.4,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    },
    b1: {
      id: "b1",
      start: new THREE.Vector2(5, 3),
      end: new THREE.Vector2(9, 2),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "b",
          sourcePosition: WallConnectionStart,
          targetPosition: 1,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: 0x00ff00,
      hoverColor: null,
      lineStyle: null,
    },
    c: {
      id: "c",
      start: new THREE.Vector2(5, 5),
      end: new THREE.Vector2(1, 1),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "b",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "d",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    },
    d: {
      id: "d",
      start: new THREE.Vector2(1, 1),
      end: new THREE.Vector2(2, 3),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "c",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "e",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.1,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    },
    e: {
      id: "e",
      start: new THREE.Vector2(2, 3),
      end: new THREE.Vector2(0, 5),
      cutouts: [
        {
          positionFromStart: 1,
          length: 0.1,
          appliesTo: new Set(["inner"]),
        },
        {
          positionFromStart: 2,
          length: 0.3,
          appliesTo: new Set(["left", "right"]),
        },
      ],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "d",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "f",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: 0x00ff00,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    },
    f: {
      id: "f",
      start: new THREE.Vector2(0, 5),
      end: new THREE.Vector2(0, 0),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "e",
          sourcePosition: WallConnectionStart,
          targetPosition: WallConnectionEnd,
        },
        {
          id: "a",
          sourcePosition: WallConnectionEnd,
          targetPosition: WallConnectionStart,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: 0x0000ff,
      hoverColor: null,
      lineStyle: null,
    },
    g: {
      id: "g",
      start: new THREE.Vector2(5, 4),
      end: new THREE.Vector2(12, 6),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [
        {
          id: "b",
          sourcePosition: WallConnectionStart,
          targetPosition: 2,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      // yellow
      fillColor: 0xffff00,
      hoverColor: null,
      lineStyle: null,
    },
    h: {
      id: "h",
      start: new THREE.Vector2(1, -3),
      end: new THREE.Vector2(6, 5),
      cutouts: [],
      clippingPolygons: [],
      type: LineType.single,
      connections: [
        {
          id: "b",
          sourcePosition: WallConnectionEnd,
          targetPosition: 0.5,
        },
      ],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: null,
      selected: null,
      lineColor: null,
      fillColor: 0xffffff,
      hoverColor: null,
      lineStyle: null,
    },
  };

  client: ApolloClient<any> | undefined;
  wallWidth: number = 0.1;
  lineWidth: number = 0.04;
  lineColor: number = 0x000000;
  fillColor: number = 0x888888;
  hoverColor: number = 0x0000ff;
  dirty: boolean = false;
  id: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  async initialize(client: ApolloClient<any>) {
    this.client = client;
    const floorplans = await this.findFloorplans();
    if (floorplans.length > 0) {
      this.loadFromCloud(floorplans[0].id);
    }
  }

  setDirty = () => {
    this.dirty = true;
  };

  setWallWidth = (width: number) => {
    this.wallWidth = width;
  };

  setLineWidth = (width: number) => {
    this.lineWidth = width;
  };

  setLineColor = (color: number) => {
    this.lineColor = color;
  };

  setFillColor = (color: number) => {
    this.fillColor = color;
  };

  setHoverColor = (color: number) => {
    this.hoverColor = color;
  };

  setWalls = (walls: { [key: string]: WallType }) => {
    this.walls = { ...this.walls, ...walls };
    Object.values(this.walls).forEach((wall) => {
      wall.lineWidth = wall.lineWidth || this.lineWidth;
      wall.wallWidth = wall.wallWidth || this.wallWidth;
    });
  };

  addWall = (start: THREE.Vector2, end: THREE.Vector2) => {
    const id = Math.random().toString(36).substr(2, 9);
    this.walls[id] = {
      id,
      start,
      end,
      cutouts: [],
      clippingPolygons: [],
      type: LineType.hollow,
      connections: [],
      wallWidth: 0.1,
      lineWidth: 0.04,
      selectable: true,
      selected: false,
      lineColor: null,
      fillColor: null,
      hoverColor: null,
      lineStyle: null,
    };
  };

  removeWall = (id: string) => {
    delete this.walls[id];
  };

  addCutout = (wallId: string, cutout: CutoutType) => {
    this.walls[wallId].cutouts.push(cutout);
  };

  removeCutout = (wallId: string, cutoutIndex: number) => {
    this.walls[wallId].cutouts.splice(cutoutIndex, 1);
  };

  updateWall = (id: string, start: THREE.Vector2, end: THREE.Vector2) => {
    this.walls[id] = { ...this.walls[id], start, end };
  };

  toJSON = (excludeProperties: string[] | undefined = undefined) => {
    const walls = Object.values(this.walls).map((wall) => {
      const wallCopy: Partial<WallType> = { ...wall };
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete wallCopy[property as keyof WallType];
        });
      }
      return wallCopy;
    });
    return JSON.stringify(walls);
  };

  fromJSON = (json: string) => {
    const parsedWalls = JSON.parse(json);
    this.walls = {};
    parsedWalls.forEach(
      (wall: {
        id: any;
        start?: THREE.Vector2;
        end?: THREE.Vector2;
        connections?: WallConnectionType[];
        cutouts?: CutoutType[];
        clippingPolygons?: ClippingPolygonType[];
        type?: LineType;
        lineWidth?: number;
        wallWidth?: number;
        selectable?: boolean | null;
        selected?: boolean | null;
        lineColor?: number | null;
        fillColor?: number | null;
        hoverColor?: number | null;
        lineStyle?: string | null;
      }) => {
        this.walls[wall.id] = {} as WallType;
        this.walls[wall.id].id = wall.id;
        this.walls[wall.id].start = new THREE.Vector2(
          wall.start?.x,
          wall.start?.y,
        );
        this.walls[wall.id].end = new THREE.Vector2(wall.end?.x, wall.end?.y);
        this.walls[wall.id].connections = wall.connections || [];
        this.walls[wall.id].cutouts =
          wall.cutouts?.forEach((cutout: CutoutType) => {
            cutout.appliesTo = new Set(cutout.appliesTo);
          }) || [];
        this.walls[wall.id].clippingPolygons = wall.clippingPolygons || [];
        this.walls[wall.id].type = wall.type || LineType.hollow;
        this.walls[wall.id].lineWidth = wall.lineWidth || 0.04;
        this.walls[wall.id].wallWidth = wall.wallWidth || 0.1;
        this.walls[wall.id].selectable = wall.selectable || true;
        this.walls[wall.id].selected = wall.selected || false;
        this.walls[wall.id].lineColor = wall.lineColor || 0x000000;
        this.walls[wall.id].fillColor = wall.fillColor || 0x888888;
        this.walls[wall.id].hoverColor = wall.hoverColor || 0x0000ff;
        this.walls[wall.id].lineStyle = wall.lineStyle || null;
      },
    );
  };

  saveToCloud = async (
    withSnapshotImage: boolean = false,
    force: boolean = false,
  ) => {
    if (!force && !this.dirty) {
      return;
    }
    const j = this.toJSON();
    const name = "Floorplan";
    const id = this.id;
    const file = null;
    const result = await this.client?.mutate<EditFloorplanMutation>({
      variables: { name: name, json: j, id: id, image: file },
      mutation: EditFloorplanDocument,
    });
    this.id = result?.data?.editFloorplan.id || "";
    this.dirty = false;
  };

  loadFromCloud = async (id: string) => {
    const { data } = (await this.client?.query({
      query: FloorplanDocument,
      variables: { id },
    })) || { data: { floorplan: null } };
    if (!data.floorplan) {
      return;
    }
    this.fromJSON(data.floorplan.json);
    this.id = data.floorplan.id;
    this.dirty = false;
  };

  findFloorplans = async () => {
    const { data } = (await this.client?.query({
      query: FloorplansDocument,
    })) || { data: { floorplans: [] } };
    return data.floorplans;
  };
}

gql`
  query Floorplan($id: String!) {
    floorplan(id: $id) {
      id
      name
      json
      image
    }
  }
`;

gql`
  query Floorplans {
    floorplans {
      id
      name
    }
  }
`;

gql`
  mutation editFloorplan(
    $id: String!
    $name: String!
    $json: String!
    $image: Upload
  ) {
    editFloorplan(id: $id, name: $name, json: $json, image: $image) {
      id
      name
      json
      image
    }
  }
`;

export const floorplannerStore = new FloorplannerStore();
export const FloorplannerStoreContext = createContext(floorplannerStore);
export const useFloorplannerStore = () => useContext(FloorplannerStoreContext);
