import React, { useState, useRef } from "react";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

interface DraggableObjectProps {
  position: [number, number];
  onDragStart: (offset: [number, number]) => void;
  onDragEnd: () => void;
  onDrag: (newPosition: [number, number]) => void;
  color: string;
  hoverColor: string;
  selectable: boolean;
  rotation?: [number, number, number];
  children?: React.ReactNode;
}

const DraggableObject: React.FC<DraggableObjectProps> = ({
  position,
  onDragStart,
  onDragEnd,
  onDrag,
  color,
  hoverColor,
  rotation,
  selectable,
  children,
}) => {
  const meshRef = useRef<THREE.Mesh>(null);
  const [isHovered, setIsHovered] = useState(false);
  const dragging = useRef(false);
  const dragOffset = useRef<[number, number] | null>(null);
  const { camera, gl } = useThree();
  const raycaster = new THREE.Raycaster();
  const [isSelected, setIsSelected] = useState(false);

  const projectToWorld = (
    clientX: number,
    clientY: number,
  ): [number, number] => {
    const rect = gl.domElement.getBoundingClientRect();
    const x = ((clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((clientY - rect.top) / rect.height) * 2 + 1;
    raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

    // Create a plane at z=0 and intersect the raycaster with this plane
    const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    const intersectPoint = new THREE.Vector3();
    raycaster.ray.intersectPlane(plane, intersectPoint);

    return [intersectPoint.x, intersectPoint.y];
  };

  const handlePointerMove = (e: PointerEvent) => {
    if (dragging.current && dragOffset.current) {
      setIsSelected(false);
      const [newX, newY] = projectToWorld(e.clientX, e.clientY);
      const [offsetX, offsetY] = dragOffset.current;
      onDrag([newX - offsetX, newY - offsetY]);
    }
  };

  const handlePointerUp = () => {
    dragging.current = false;
    dragOffset.current = null;
    onDragEnd();

    // Remove event listeners from the canvas
    gl.domElement.removeEventListener("pointermove", handlePointerMove);
    gl.domElement.removeEventListener("pointerup", handlePointerUp);
  };

  const handlePointerDown = (e: ThreeEvent<PointerEvent>) => {
    if (selectable) {
      setIsSelected(!isSelected);
    }
    dragging.current = true;
    const [worldX, worldY] = projectToWorld(e.clientX, e.clientY);
    const offsetX = worldX - position[0];
    const offsetY = worldY - position[1];
    dragOffset.current = [offsetX, offsetY];
    onDragStart([offsetX, offsetY]);

    // Attach event listeners to the canvas
    gl.domElement.addEventListener("pointermove", handlePointerMove);
    gl.domElement.addEventListener("pointerup", handlePointerUp);
    e.stopPropagation();
  };

  useFrame(() => {
    if (meshRef.current) {
      const scale = isHovered || isSelected ? 1.1 : 1;
      meshRef.current.scale.set(scale, scale, scale);
    }
  });

  return (
    <group>
      <mesh
        position={[position[0], position[1], 0]}
        rotation={rotation}
        onPointerDown={handlePointerDown}
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
      >
        {children}
      </mesh>
    </group>
  );
};

export default DraggableObject;
