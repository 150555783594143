import {
  WallConnectionEnd,
  WallConnectionStart,
  WallShapeType,
  WallType,
} from "../../types/wallTypes";
import { checkLineIntersection } from "./checkLineIntersections";
import * as THREE from "three";

/**
 * Trim intersecting lines by cutting the paths of the wall shapes at the intersections
 * @param wallShapes
 * @param otherShapes
 * @returns
 */
export const trimIntersectingLines = (
  wallShapes: WallShapeType[],
  otherShapes: WallShapeType[] | undefined,
  walls: { [key: string]: WallType },
) => {
  for (let i = 0; i < wallShapes.length; i++) {
    const wall = wallShapes[i].wall;
    const startConnection = wall.connections.find(
      (c) => c.sourcePosition === WallConnectionStart,
    );
    const startConnectedTo = wallShapes.find(
      (w) => w.wall.id === startConnection?.id,
    );
    const endConnection = wall.connections.find(
      (c) => c.sourcePosition === WallConnectionEnd,
    );
    const endConnectedTo = wallShapes.find(
      (w) => w.wall.id === endConnection?.id,
    );
    if (startConnectedTo) {
      if (
        startConnection?.targetPosition === WallConnectionStart ||
        startConnection?.targetPosition === WallConnectionEnd
      ) {
        // End to end connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        const intersection = checkLineIntersection(
          wallShapes[i].paths[0],
          wallShapes[i].paths[3],
          startConnectedTo.paths[0],
          startConnectedTo.paths[3],
          false,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[0] = intersection.point as THREE.Vector2;
          startConnectedTo.paths[3] = intersection.point as THREE.Vector2;
        }
      } else {
        // End to middle connections, we only need to check the intersections of the connecting wall edges
        let intersection = checkLineIntersection(
          wallShapes[i].paths[0],
          wallShapes[i].paths[3],
          startConnectedTo.paths[0],
          startConnectedTo.paths[3],
          true,
        );
        let clip = false;
        if (intersection.intersecting) {
          wallShapes[i].paths[0] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[1],
          wallShapes[i].paths[2],
          startConnectedTo.paths[0],
          startConnectedTo.paths[3],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[1] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[0],
          wallShapes[i].paths[3],
          startConnectedTo.paths[1],
          startConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[0] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[1],
          wallShapes[i].paths[2],
          startConnectedTo.paths[1],
          startConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[1] = intersection.point as THREE.Vector2;
          clip = true;
        }
        // We may also get intersections with other lines/side of the connecting wall
        if (otherShapes) {
          for (let j = 0; j < otherShapes.length; j++) {
            const otherWall = otherShapes[j].wall;
            if (otherWall.id === startConnectedTo.wall.id) {
              let intersection = checkLineIntersection(
                wallShapes[i].paths[0],
                wallShapes[i].paths[3],
                otherShapes[j].paths[0],
                otherShapes[j].paths[3],
                true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[0] = intersection.point as THREE.Vector2;
                clip = true;
              }
              intersection = checkLineIntersection(
                wallShapes[i].paths[1],
                wallShapes[i].paths[2],
                otherShapes[j].paths[0],
                otherShapes[j].paths[3],
                true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[1] = intersection.point as THREE.Vector2;
                clip = true;
              }
            }
          }
        }
        if (clip) {
          const startConnectedToWall = walls[startConnectedTo.wall.id];
          const clippingPolygon = startConnectedToWall.clippingPolygons.find(
            (p) => p.belongsTo === wall.id,
          );
          if (clippingPolygon) {
            clippingPolygon.polygon = [
              new THREE.Vector2(
                wallShapes[i].paths[0].x,
                wallShapes[i].paths[0].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[1].x,
                wallShapes[i].paths[1].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[2].x,
                wallShapes[i].paths[2].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[3].x,
                wallShapes[i].paths[3].y,
              ),
            ];
          } else {
            startConnectedToWall.clippingPolygons.push({
              belongsTo: wall.id,
              polygon: [
                new THREE.Vector2(
                  wallShapes[i].paths[0].x,
                  wallShapes[i].paths[0].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[1].x,
                  wallShapes[i].paths[1].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[2].x,
                  wallShapes[i].paths[2].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[3].x,
                  wallShapes[i].paths[3].y,
                ),
              ],
            });
          }
        }
      }
    }
    if (endConnectedTo) {
      if (
        endConnection?.targetPosition === WallConnectionStart ||
        endConnection?.targetPosition === WallConnectionEnd
      ) {
        // End to end connections, we need to extend the intersection checking for hit on sharp corners (restrictToSegment = false)
        const intersection = checkLineIntersection(
          wallShapes[i].paths[1],
          wallShapes[i].paths[2],
          endConnectedTo.paths[1],
          endConnectedTo.paths[2],
          false,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[2] = intersection.point as THREE.Vector2;
          endConnectedTo.paths[1] = intersection.point as THREE.Vector2;
        }
      } else {
        // End to middle connections, we only need to check the intersections of the connecting wall edges
        let intersection = checkLineIntersection(
          wallShapes[i].paths[1],
          wallShapes[i].paths[2],
          endConnectedTo.paths[0],
          endConnectedTo.paths[3],
          true,
        );
        let clip = false;
        if (intersection.intersecting) {
          wallShapes[i].paths[2] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[0],
          wallShapes[i].paths[3],
          endConnectedTo.paths[0],
          endConnectedTo.paths[3],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[3] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[1],
          wallShapes[i].paths[2],
          endConnectedTo.paths[1],
          endConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[2] = intersection.point as THREE.Vector2;
          clip = true;
        }
        intersection = checkLineIntersection(
          wallShapes[i].paths[0],
          wallShapes[i].paths[3],
          endConnectedTo.paths[1],
          endConnectedTo.paths[2],
          true,
        );
        if (intersection.intersecting) {
          wallShapes[i].paths[3] = intersection.point as THREE.Vector2;
          clip = true;
        }
        // We may also get intersections with other lines/side of the connecting wall
        if (otherShapes) {
          for (let j = 0; j < otherShapes.length; j++) {
            const otherWall = otherShapes[j].wall;
            if (otherWall.id === endConnectedTo.wall.id) {
              let intersection = checkLineIntersection(
                wallShapes[i].paths[1],
                wallShapes[i].paths[2],
                otherShapes[j].paths[1],
                otherShapes[j].paths[2],
                true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[2] = intersection.point as THREE.Vector2;
                clip = true;
              }
              intersection = checkLineIntersection(
                wallShapes[i].paths[0],
                wallShapes[i].paths[3],
                otherShapes[j].paths[1],
                otherShapes[j].paths[2],
                true,
              );
              if (intersection.intersecting) {
                wallShapes[i].paths[3] = intersection.point as THREE.Vector2;
                clip = true;
              }
            }
          }
        }
        if (clip) {
          const endConnectedToWall = walls[endConnectedTo.wall.id];
          const clippingPolygon = endConnectedToWall.clippingPolygons.find(
            (p) => p.belongsTo === wall.id,
          );
          if (clippingPolygon) {
            clippingPolygon.polygon = [
              new THREE.Vector2(
                wallShapes[i].paths[0].x,
                wallShapes[i].paths[0].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[1].x,
                wallShapes[i].paths[1].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[2].x,
                wallShapes[i].paths[2].y,
              ),
              new THREE.Vector2(
                wallShapes[i].paths[3].x,
                wallShapes[i].paths[3].y,
              ),
            ];
          } else {
            endConnectedToWall.clippingPolygons.push({
              belongsTo: wall.id,
              polygon: [
                new THREE.Vector2(
                  wallShapes[i].paths[0].x,
                  wallShapes[i].paths[0].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[1].x,
                  wallShapes[i].paths[1].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[2].x,
                  wallShapes[i].paths[2].y,
                ),
                new THREE.Vector2(
                  wallShapes[i].paths[3].x,
                  wallShapes[i].paths[3].y,
                ),
              ],
            });
          }
        }
      }
    }
  }
};
