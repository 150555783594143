import { observer } from "mobx-react-lite";

const SidebarLeft = observer(() => {
  return (
    <div
      className="sidebar-left bg-eggshell"
      style={{
        display: "flex",
        flexDirection: "column",
        width: 100,
        padding: 10,
        borderRight: "1px solid #ccc",
      }}
    >
      <div className="bg-stone-100 inline-flex h-[848px] w-24 flex-col items-start justify-start gap-2 pt-4">
        <div className="flex h-[82px] flex-col items-center justify-start gap-1 self-stretch px-5 pb-1 pt-2">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Floorplan
            <br />
            templates
          </div>
        </div>
        <div className="flex h-[62px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Symbols
          </div>
        </div>
        <div className="flex h-[62px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Products
          </div>
        </div>
        <div className="flex h-[78px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Paint & <br />
            Wallpaper
          </div>
        </div>
        <div className="flex h-[62px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Favorites
          </div>
        </div>
        <div className="flex h-[62px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Upload
          </div>
        </div>
        <div className="flex h-[78px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Custom <br />
            products
          </div>
        </div>
        <div className="flex h-[62px] flex-col items-center justify-start gap-1 self-stretch px-5 py-1">
          <div className="relative h-[34px] w-11 rounded-sm border border-eggshell300" />
          <div className="text-center font-dm text-xs font-normal text-black">
            Text
          </div>
        </div>
      </div>
    </div>
  );
});

export default SidebarLeft;
