import React, { useState, useEffect } from "react";

const PropertyPanel: React.FC = () => {
  return (
    <>
      <h2>Properties</h2>
    </>
  );
};

export default PropertyPanel;
