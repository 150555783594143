import * as THREE from "three";

export const calculateLineVertices = (
  start: THREE.Vector2,
  end: THREE.Vector2,
  offset: number,
  lineWidth: number,
  wallWidth: number,
  shapeType: string,
): THREE.Vector2[] => {
  const direction = new THREE.Vector2().subVectors(end, start).normalize();
  const width = shapeType === "inner" ? wallWidth : lineWidth;
  const perpendicular = new THREE.Vector2(
    -direction.y,
    direction.x,
  ).multiplyScalar(width / 2);
  const offsetVector = perpendicular.clone().multiplyScalar(offset / width);
  const extendLength = shapeType === "inner" ? lineWidth * 2 : width * 2;

  start = start.clone().sub(direction.clone().multiplyScalar(extendLength));
  end = end.clone().add(direction.clone().multiplyScalar(extendLength));
  const startOffset = start.clone().add(offsetVector);
  const endOffset = end.clone().add(offsetVector);

  if (
    shapeType === "endCapBoth" ||
    shapeType === "endCapStart" ||
    shapeType === "endCapEnd"
  ) {
    // Extend vertically at the start end cap
    // Use wallWidth directly, similar to how lineWidth is used
    const extendedPerpendicular = perpendicular
      .clone()
      .multiplyScalar((wallWidth + lineWidth * 2) / lineWidth);

    const startCap1 = startOffset.clone().add(extendedPerpendicular);
    const startCap2 = startOffset.clone().sub(extendedPerpendicular);
    const endCap1 = startOffset
      .clone()
      .add(extendedPerpendicular)
      .sub(direction.clone().multiplyScalar(lineWidth));
    const endCap2 = startOffset
      .clone()
      .sub(extendedPerpendicular)
      .sub(direction.clone().multiplyScalar(lineWidth));

    // Extend vertically at the end end cap
    const endCap3 = endOffset.clone().add(extendedPerpendicular);
    const endCap4 = endOffset.clone().sub(extendedPerpendicular);
    const startCap3 = endOffset
      .clone()
      .add(extendedPerpendicular)
      .add(direction.clone().multiplyScalar(lineWidth));
    const startCap4 = endOffset
      .clone()
      .sub(extendedPerpendicular)
      .add(direction.clone().multiplyScalar(lineWidth));
    // return [
    //     startCap1, startCap2, endCap2, endCap1, // Start end cap
    //     endCap3, endCap4, startCap4, startCap3 // End end cap
    // ];
    if (shapeType === "endCapBoth") {
      return [
        startCap1,
        startCap2,
        endCap2,
        endCap1, // Start end cap
        endCap3,
        endCap4,
        startCap4,
        startCap3, // End end cap
      ];
    } else if (shapeType === "endCapStart") {
      return [
        startCap1,
        startCap2,
        endCap2,
        endCap1, // Start end cap
      ];
    } else {
      return [
        endCap3,
        endCap4,
        startCap4,
        startCap3, // End end cap
      ];
    }
  } else {
    return [
      startOffset.clone().add(perpendicular),
      startOffset.clone().sub(perpendicular),
      endOffset.clone().sub(perpendicular),
      endOffset.clone().add(perpendicular),
    ];
  }
};
