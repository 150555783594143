import React from "react";
import { DoorType } from "../../types/wallTypes";
import DraggableObject from "./DraggableObject";

interface DoorProps {
  door: DoorType;
  onDragStart: (door: DoorType, offset: [number, number]) => void;
  onDragEnd: () => void;
  onDrag: (newPosition: [number, number]) => void;
}

const Door: React.FC<DoorProps> = ({
  door,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  return (
    <DraggableObject
      position={door.position}
      onDragStart={(offset) => onDragStart(door, offset)}
      onDragEnd={onDragEnd}
      onDrag={onDrag}
      color="brown"
      hoverColor="darkred"
      selectable={true}
    >
      <mesh>
        <boxGeometry args={[0.1, 0.5, 0.1]} />
        <meshStandardMaterial color="brown" />
      </mesh>
    </DraggableObject>
  );
};

export default Door;
