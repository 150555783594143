import * as THREE from "three";
import { WallType } from "../../types/wallTypes";

export const handleWidth = 0.5;
export const handleSize = 0.1;
export const tubeRadius = 0.02;

export const createMiddleDragHandle = (wall: WallType, wallWidth: number) => {
  const shape = new THREE.Shape();
  const height = (wall.wallWidth || wallWidth) + handleSize * 1.5;
  shape.moveTo(-handleWidth / 2, -height / 2);
  shape.lineTo(handleWidth / 2, -height / 2);
  shape.lineTo(handleWidth / 2, height / 2);
  shape.lineTo(-handleWidth / 2, height / 2);
  shape.lineTo(-handleWidth / 2, -height / 2);

  const hole = new THREE.Path();
  const holeWidth = handleWidth - 0.02;
  const holeHeight = height - 0.02;
  hole.moveTo(-holeWidth / 2, -holeHeight / 2);
  hole.lineTo(holeWidth / 2, -holeHeight / 2);
  hole.lineTo(holeWidth / 2, holeHeight / 2);
  hole.lineTo(-holeWidth / 2, holeHeight / 2);
  hole.lineTo(-holeWidth / 2, -holeHeight / 2);

  shape.holes.push(hole);

  return new THREE.ExtrudeGeometry(shape, {
    depth: handleSize,
    bevelEnabled: false,
  });
};
