import { makeAutoObservable } from "mobx";
import React from "react";
import { useContext } from "react";

class EditorStore {
  view3D = false;

  constructor() {
    makeAutoObservable(this);
  }

  setView3D = (view3D: boolean) => {
    this.view3D = view3D;
  };
}

export const editorStore = new EditorStore();
export const editorStoreContext = React.createContext<EditorStore>(editorStore);
export const useEditorStore = () => useContext(editorStoreContext);
